import React from "react";
import AnyChart from "anychart-react";

const PieChart = ({ data, type, currency, id }) => {
  console.log("datapie", data);
  // Define the data for the pie chart
  // Define pie chart settings

  const formatRatio = (value) => {
    if (value === 0) return "0";
    if (value === 1) return "1";
    return Math.floor(value / Math.pow(10, Math.floor(Math.log10(value)) - 1));
  };

  const formatTooltip = (type) => {
    if (type === "profit-loss") {
      const profit = data?.find((item) => item.x === "Profit");
      const loss = data?.find((item) => item.x === "Loss");

      if (profit?.value > loss?.value) {
        const formattedProfit = profit
          ? String(profit?.value)?.slice(0, 3)
          : "0";
        const formattedLoss = loss ? String(loss?.value)?.slice(0, 2) : "0";

        const ratio =
          profit && loss ? `${formattedProfit}:${formattedLoss}` : "N/A";
        localStorage.setItem("profit", formattedProfit);
        localStorage.setItem("loss", formattedLoss);
        return `Profit: ${formattedProfit}\nLoss: ${formattedLoss}\nRatio: ${ratio}`;
      } else if (profit?.value < loss?.value) {
        const formattedProfit = profit
          ? String(profit?.value)?.slice(0, 2)
          : "0";
        const formattedLoss = loss ? String(loss?.value)?.slice(0, 3) : "0";
        localStorage.setItem("profit", formattedProfit);
        localStorage.setItem("loss", formattedLoss);
        const ratio =
          profit && loss ? `${formattedProfit}:${formattedLoss}` : "N/A";

        return `Profit: ${formattedProfit}\nLoss: ${formattedLoss}\nRatio: ${ratio}`;
      }
    }
    return `{%x}: ${currency}{%value}`;
  };

  const chartSettings = {
    type: "pie",
    data: data,
    height: "500px",
    width: "600px",
    palette: ["#ff8038", "#243a69"],
    //  radius: "40%",
    labels: {
      position: "outside",
      // format: "{%value}",
      format: (ctx) => {
        if (type === "profit-loss") {
          const profit = data?.find((item) => item?.x === "Profit");
          const loss = data?.find((item) => item?.x === "Loss");

          if (profit && loss) {
            const formattedProfit =
              profit?.value > loss?.value
                ? String(profit?.value)?.slice(0, 3)
                : String(profit?.value)?.slice(0, 2);

            const formattedLoss =
              profit?.value < loss?.value
                ? String(loss?.value)?.slice(0, 3)
                : String(loss?.value)?.slice(0, 2);

            const ratio = `${formattedProfit}:${formattedLoss}`;

            return `\n\n\nnProfit: ${formattedProfit}\nLoss: ${formattedLoss}\nRatio: ${ratio}\n`;
          }
        }
        const profits = data?.find((item) => item.x === "Profit");
        const losss = data?.find((item) => item.x === "Loss");
        return `\nProfit:  ${currency}${
          profits.value === "0.00000" ? 0 : profits?.value
        }\nLoss:  ${currency}${losss?.value}`; // Default format if not profit/loss
      },
      fontColor: "#000",
      fontSize: 14,
      padding: 10,
    },
    tooltip: {
      format: formatTooltip(type),
    },

    minAngle: 1, // Ensures even small slices (including zero) are visible
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <AnyChart id={id} {...chartSettings} />
    </div>
  );
};

export default PieChart;
