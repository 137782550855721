import React, { useLayoutEffect, useRef } from "react";
import anychart from "anychart";

const BarChart2 = ({ data, id }) => {
  const chartRef = useRef(null); // Ref to store the chart instance

  useLayoutEffect(() => {
    // Clear the previous chart if it exists
    if (chartRef.current) {
      chartRef.current.dispose(); // Properly dispose of the previous chart instance
      chartRef.current = null;
    }

    // Create and render the chart
    const dataSet = anychart.data.set(data);

    const firstSeriesData = dataSet.mapAs({ x: 0, value: 1 });
    const secondSeriesData = dataSet.mapAs({ x: 0, value: 2 });

    const chart = anychart.bar();
    chart.animation(true);
    chart.padding([10, 20, 5, 20]);
    chart.yScale().stackMode("value");

    chart
      .yAxis()
      .labels()
      .format(function () {
        return Math.abs(this.value).toLocaleString();
      });
    chart.yAxis(0).title("Profit/Loss");

    chart.xAxis(0).overlapMode("allow-overlap");
    chart
      .xAxis(1)
      .enabled(true)
      .orientation("right")
      .overlapMode("allow-overlap");

    chart.interactivity().hoverMode("by-x");
    chart
      .tooltip()
      .title(false)
      .separator(false)
      .displayMode("separated")
      .positionMode("point")
      .useHtml(true)
      .fontSize(12)
      .offsetX(5)
      .offsetY(0)
      .format(function () {
        return (
          '<span style="color: #D9D9D9">' +
          this.seriesName + // Display series name (Profit or Loss)
          ": </span>$" +
          Math.abs(this.value).toLocaleString()
        );
      });

    let series = chart.bar(firstSeriesData);
    series.name("Profit").color("#00df75");
    series.tooltip().position("right").anchor("left-center");
    series = chart.bar(secondSeriesData);
    series.name("Loss").color("#ff685c");
    series.tooltip().position("left").anchor("right-center");

    chart
      .legend()
      .enabled(true)
      .inverted(true)
      .fontSize(13)
      .padding([0, 0, 20, 0]);

    chart.container(id); // Bind chart to the container
    chart.draw(); // Draw the chart

    // Store the chart instance in the ref
    chartRef.current = chart;

    // Cleanup function to dispose of the chart on unmount or re-render
    return () => {
      if (chartRef.current) {
        chartRef.current.dispose();
        chartRef.current = null;
      }
    };
  }, [data, id]); // Re-run the effect only if `data` or `id` changes

  return <div id={id} style={{ width: "100%", height: "500px" }} />;
};

export default BarChart2;
