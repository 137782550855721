import React from "react";
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from "react-icons/io";

const BiggerColumn = ({
  mainTab,
  totalProfit,
  totalLoss,
  closeTotalProfit,
  closeTotalLoss,
  userBalance,
  totalDepositWithdrawl,
  leverage,
  numberOfProfitLoss,
}) => {
  return (
    <div
      className="summary-body-h"
      style={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid #cbd0dd",
        borderRadius: "10px",
        padding: "15px 20px",
        width: "100%",
        marginTop: "10px",
        gap: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          //   border: "1px solid #cbd0dd",
          //   borderRadius: "10px",
          //   padding: "10px 15px",
          width: "100%",
          //   marginTop: "10px",
          // position: "absolute",
          // top: "50%",
          //zIndex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
          }}
        >
          <h6
            style={{
              color: "grey",
              fontSize: "15px",
              marginBottom: "0px",
            }}
          >
            UnRealized Profit/Loss{" "}
          </h6>
          <div style={{ display: "flex" }}>
            {Math.abs(Number(totalProfit)?.toFixed(3)) >
            Math.abs(Number(totalLoss)?.toFixed(3)) ? (
              <h5 style={{ color: "green" }}>
                {Number(totalProfit)?.toFixed(3)}
              </h5>
            ) : (
              <h5 style={{ color: "red" }}>{Number(totalLoss)?.toFixed(3)}</h5>
            )}
            {Math.abs(Number(totalProfit)?.toFixed(3)) >
            Math.abs(Number(totalLoss)?.toFixed(3)) ? (
              <span
                style={{
                  color: "#00df75",
                  "font-size": "14px;",
                }}
              >
                <IoMdArrowRoundUp />
              </span>
            ) : (
              <span
                style={{
                  color: "#ff685c",
                  "font-size": "14px;",
                }}
              >
                <IoMdArrowRoundDown />
              </span>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
          }}
        >
          <h6
            style={{
              color: "grey",
              fontSize: "15px",
              marginBottom: "0px",
            }}
          >
            Realized Profit/Loss
            <span
              style={{
                fontSize: "12px",
              }}
            >
              {/* ({mainTab === "open" ? "open" : "close"}) */}
            </span>
          </h6>
          <div style={{ display: "flex" }}>
            {Math.abs(Number(closeTotalLoss)) >
            Math.abs(Number(closeTotalProfit)) ? (
              <h5 style={{ color: "red" }}>
                {Number(closeTotalLoss)?.toFixed(3)}
              </h5>
            ) : (
              <h5 style={{ color: "green" }}>
                {Number(closeTotalProfit)?.toFixed(3)}
              </h5>
            )}

            {Math.abs(Number(closeTotalLoss)) >
            Math.abs(Number(closeTotalProfit)) ? (
              <span
                style={{
                  color: "#ff685c",
                  "font-size": "14px;",
                }}
              >
                <IoMdArrowRoundDown />
              </span>
            ) : (
              <span
                style={{
                  color: "#00df75",
                  "font-size": "14px;",
                }}
              >
                <IoMdArrowRoundUp />
              </span>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
          }}
        >
          <h6
            style={{
              color: "grey",
              fontSize: "15px",
              marginBottom: "0px",
            }}
          >
            Profit Stocks
            <span
              style={{
                fontSize: "12px",
              }}
            >
              ({mainTab === "open" ? "open" : "close"})
            </span>
          </h6>
          <h5 style={{ color: "#000" }}>
            {" "}
            {mainTab == "open"
              ? numberOfProfitLoss?.openProfitStockss || 0
              : numberOfProfitLoss?.closeProfitStockss}
          </h5>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "25%",
          }}
        >
          <h6
            style={{
              color: "grey",
              fontSize: "15px",
              marginBottom: "0px",
            }}
          >
            Loss Stocks
            <span
              style={{
                fontSize: "12px",
              }}
            >
              ({mainTab === "open" ? "open" : "close"})
            </span>
          </h6>
          <h5 style={{ color: "#000" }}>
            {" "}
            {mainTab == "open"
              ? numberOfProfitLoss?.openLossStocks || 0
              : numberOfProfitLoss?.closeLossStocks}{" "}
          </h5>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          //   border: "1px solid #cbd0dd",
          //   borderRadius: "10px",
          //   padding: "10px 15px",
          width: "100%",
          //   marginTop: "10px",
          // position: "absolute",
          // top: "50%",
          //zIndex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
          }}
        >
          <h6
            style={{
              color: "grey",
              fontSize: "15px",
              marginBottom: "0px",
            }}
          >
            Balance
          </h6>
          <h5 style={{ color: "#000" }}>${userBalance || 0}</h5>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
          }}
        >
          <h6
            style={{
              color: "grey",
              fontSize: "15px",
              marginBottom: "0px",
            }}
          >
            Leverage{" "}
            <span
              style={{
                fontSize: "12px",
              }}
            >
              ({mainTab === "open" ? "open" : "close"})
            </span>
          </h6>
          <h5 style={{ color: "#000" }}>
            {mainTab === "open"
              ? !isNaN(leverage?.openLeverage)
                ? Number(leverage?.openLeverage).toFixed(3)
                : 0
              : !isNaN(leverage?.closeLeverage)
              ? Number(leverage?.closeLeverage).toFixed(3)
              : 0}
            %
          </h5>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "40%",
          }}
        >
          <h6
            style={{
              color: "grey",
              fontSize: "15px",
              marginBottom: "0px",
            }}
          >
            Deposit
          </h6>
          <h5 style={{ color: "#000" }}>
            ${totalDepositWithdrawl?.totalDeposits}
          </h5>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "25%",
          }}
        >
          <h6
            style={{
              color: "grey",
              fontSize: "15px",
              marginBottom: "0px",
            }}
          >
            Withdraw
          </h6>
          <h5 style={{ color: "#000" }}>
            ${totalDepositWithdrawl?.totalWithdrawals}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default BiggerColumn;
